import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import "./Datatable.css"
import PropTypes from 'prop-types';

function DataTable({ hideMundyName, data }) {
    console.log(data)
    const [showDialog, setShowDialog] = useState(false);
    const [item, setItem] = useState([])

    const handleRowClick = (it) => {
        console.log("hello")
        setItem(it)
        setShowDialog(true);
    };

    const handleCloseDialog = () => {
        console.log("hello")
        setShowDialog(false);
    };
    return (
        <div className='datatable'>
            <table>
                <thead>
                    <tr>
                        {/* <th>ID</th> */}
                        <th style={{ display: hideMundyName ? 'none' : 'table-cell' }}>Mundy</th>
                        <th>Type</th>
                        <th>Date</th>
                        <th>Place of Loading</th>
                        <th>Location</th>
                        <th>Variety</th>
                        <th>Truck No.</th>
                        <th>Net Wt.</th>
                        <th>Rate per kg</th>
                        <th>Payable QTY</th>
                        <th>Coolie</th>
                        <th>Chute</th>
                        <th>Truck</th>
                        <th>Total Amount</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((item, index) => (
                        <React.Fragment key={index}>
                            <tr onClick={() => handleRowClick(item)}>
                                {/* <td>{item.id}</td> */}
                                <td id='mundy_name' style={{ display: hideMundyName ? 'none' : 'table-cell' }}>
                                    <Link to={`/mundy?mundy_name=${item.purchase_from_mundy_name}`}>
                                        {item.purchase_from_mundy_name}
                                    </Link>
                                </td>
                                <td>P</td>
                                <td>{item.formatted_purchase_date}</td>
                                <td>{item.place_of_loading}</td>
                                <td>{item.purchase_variety}</td>
                                <td>{item.purchase_truck_no}</td>
                                <td>{item.netWt}</td>
                                <td>{item.chutePercentage}</td>
                                <td>{item.payableQuantity}</td>
                                <td>{item.ratePerKg}</td>
                                <td>{parseFloat(item.amount).toFixed(1)}</td>
                            </tr>
                            <tr onClick={() => handleRowClick(item)}>
                                <td style={{ display: hideMundyName ? 'none' : 'table-cell' }}></td>
                                <td>S</td>
                                <td>{item.formatted_sale_date}</td>
                                <td>{item.place_of_delivery}</td>
                                <td>{item.sale_variety}</td>
                                <td>{item.sale_truck_no}</td>


                            </tr>
                        </React.Fragment>
                    ))}
                </tbody>
            </table>
            {showDialog && (
                <div className="dialog">
                    <div className="dialog-close" onClick={() => handleCloseDialog()}>close</div>
                    <div className="dialog-content">
                        <p>This is the content of the dialogue box.</p>
                        <p>Name: {item.formatted_purchase_date}</p>
                        <p>Age: {item.age}</p>
                    </div>
                </div>
            )}
        </div>
    );
}

DataTable.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object).isRequired // Validate 'data' prop as an array of objects
};

export default DataTable;





// amount
// : 
// "4850.000"
// amount_sales
// : 
// "5000.000"
// chute
// : 
// "30.000"
// coolie
// : 
// "3.00"
// despatched_to
// : 
// "Destination"
// formatted_purchase_date
// : 
// "09-03-24"
// formatted_sale_date
// : 
// "10-03-24"
// payable_quantity
// : 
// "970.000"
// place_of_delivery
// : 
// "Delivery Place"
// place_of_loading
// : 
// "Loading Place"
// purchase_date
// : 
// "2024-03-08T18:30:00.000Z"
// purchase_from_mundy_name
// : 
// "Mundy Name"
// purchase_net_weight
// : 
// "1000.000"
// purchase_rate_per_kg
// : 
// "5.000"
// purchase_truck_no
// : 
// "Truck123"
// purchase_variety
// : 
// "Variety"
// sale_date
// : 
// "2024-03-09T18:30:00.000Z"
// sale_net_weight
// : 
// "900.000"
// sale_rate_per_kg
// : 
// "4.500"
// sale_truck_no
// : 
// "Truck456"
// sale_variety
// : 
// "Another Variety"
// thatti
// : 
// "2.00"
// total_bill_amount
// : 
// "4900.000"
// total_truck_hire
// : 
// "100.000"
// truck_adv
// : 
// "50.000"
// truck_hire_balance
// : 
// "50.000"
