import React, { useEffect, useState } from 'react';
import "./Home.css";
import { useLocation } from 'react-router-dom';
import DataTable from '../components/Datatable';
import axios from "axios";
import Header from '../components/Header/Header.js';

const Mundy = () => {
    const [cars, setCars] = useState([]);
    const REACT_APP_BASE_API_URL = process.env.REACT_APP_BASE_API_URL
    console.log(REACT_APP_BASE_API_URL)

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const mundyName = searchParams.get('mundy_name');
    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const response = await axios.get(`${REACT_APP_BASE_API_URL}/view/cars`);
            setCars(response.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };



    return (
        <div className='Mundy'>
            <Header />
            <h1>Hi {mundyName}</h1>

            <DataTable hideMundyName={true} data={cars} />
        </div>
    );
}

export default Mundy;