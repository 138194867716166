import React from 'react';
import Header from '../components/Header/Header.js';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { makeStyles } from '@material-ui/core/styles';
import {
    Container,
    Grid,
    Typography
} from '@material-ui/core';
import Textfield from '../components/FormsUI/Textfield';
// import Select from '../components/FormsUI/Select';
import DateTimePicker from '../components/FormsUI/DateTimePicker';
import Button from '../components/FormsUI/Button';
import { useNavigate } from 'react-router-dom';


const useStyles = makeStyles((theme) => ({
    formWrapper: {
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(8),
    },
}));

const INITIAL_FORM_STATE = {
    date: '',
    purchaseFromMundyName: '',
    placeOfLoading: '',
    variety: '',
    truckNo: '',
    netWt: '',
    chute: '',
    payableQuantity: '',
    ratePerKg: '',
    amount: '',
    slNo: '',
    despactedTo: '',
    placeOfDelivery: '',
    saleDate: '',
    saleTruckNo: '',
    saleVariety: '',
    saleNetWt: '',
    saleRatePerKg: '',
    thattiBambu: '',
    loadingCoolie: '',
    totalTruckHire: '',
    truckAdv: '',
    truckHireBalance: '',
    totalBillAmt: '',
    coolie: 0,
};

const FORM_VALIDATION = Yup.object().shape({
    // Define validation schema based on your requirements
    // Example:
    date: Yup.date().required('Required'),
    purchaseFromMundyName: Yup.string().required('Required'),
    // Add validation for other fields
});

// const FORM_VALIDATION = Yup.object().shape({
//     firstName: Yup.string()
//         .required('Required'),
//     lastName: Yup.string()
//         .required('Required'),
//     email: Yup.string()
//         .email('Invalid email.')
//         .required('Required'),
//     phone: Yup.number()
//         .integer()
//         .typeError('Please enter a valid phone number')
//         .required('Required'),
//     addressLine1: Yup.string()
//         .required('Required'),
//     addressLine2: Yup.string(),
//     city: Yup.string()
//         .required('Required'),
//     state: Yup.string()
//         .required('Required'),
//     country: Yup.string()
//         .required('Required'),
//     arrivealDate: Yup.date()
//         .required('Required'),
//     departureDate: Yup.date()
//         .required('Required'),
//     message: Yup.string(),
//     termsOfService: Yup.boolean()
//         .oneOf([true], 'The terms and conditions must be accepted.')
//         .required('The terms and conditions must be accepted.'),
// });

const AddEntry = () => {
    const classes = useStyles();
    const navigate = useNavigate();

    const handleSubmit = async (values) => {
        try {
            // Send form values to API endpoint
            const response = await fetch('your-api-endpoint', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(values),
            });

            if (!response.ok) {
                throw new Error('Failed to submit form');
            }

            // Redirect to homepage after successful submission
            navigate('/');
        } catch (error) {
            console.error('Error submitting form:', error);
            // Handle error, show error message, etc.
        }
    };

    return (
        <Grid container>
            <Grid item xs={12}>
                <Header />
            </Grid>
            <Grid item xs={12}>
                <Container maxWidth="md">
                    <div className={classes.formWrapper}>

                        <Formik
                            initialValues={INITIAL_FORM_STATE}
                            validationSchema={FORM_VALIDATION}
                            onSubmit={handleSubmit}
                        >
                            <Form>

                                <Grid container spacing={2}>

                                    <Grid item xs={12}>
                                        <Typography variant="h5">
                                            Add Entry
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <h2>Purchase</h2>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <DateTimePicker
                                            name="date"
                                            label="Date"
                                        />
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Textfield
                                            name="purchaseFromMundyName"
                                            label="Purchase from Mundy Name"
                                        />
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Textfield
                                            name="placeOfLoading"
                                            label="Place of Loading"
                                        />
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Textfield
                                            name="variety"
                                            label="Variety"
                                        />
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Textfield
                                            name="truckNo"
                                            label="Truck No."
                                        />
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Textfield
                                            name="netWt"
                                            label="Net Wt."
                                        />
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Textfield
                                            name="chute"
                                            label="Chute @ 3%"
                                        />
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Textfield
                                            name="payableQuantity"
                                            label="Payable Quantity"
                                        />
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Textfield
                                            name="ratePerKg"
                                            label="Rate per KG"
                                        />
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Textfield
                                            name="amount"
                                            label="Amount"
                                        />
                                    </Grid>



                                    <Grid item xs={12}>
                                        <h2>Sale</h2>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <DateTimePicker
                                            name="saleDate"
                                            label="Sale Date"
                                        />
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Textfield
                                            name="despactedTo"
                                            label="Despatched to"
                                        />
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Textfield
                                            name="placeOfDelivery"
                                            label="Place of Delivery"
                                        />
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Textfield
                                            name="saleTruckNo"
                                            label="Sale Truck No."
                                        />
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Textfield
                                            name="saleVariety"
                                            label="Sale Variety"
                                        />
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Textfield
                                            name="saleNetWt"
                                            label="Sale Net Wt."
                                        />
                                    </Grid>



                                    <Grid item xs={6}>
                                        <Textfield
                                            name="saleRatePerKg"
                                            label="Sale Rate per KG"
                                        />
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Textfield
                                            name="thattiBambu"
                                            label="Thatti Bambu"
                                        />

                                    </Grid>

                                    <Grid item xs={6}>
                                        <Textfield
                                            name="coolie"
                                            label="coolie"
                                        />

                                    </Grid>

                                    <Grid item xs={12}>
                                        <Button>
                                            Submit Form
                                        </Button>
                                    </Grid>


                                </Grid>

                            </Form>
                        </Formik>

                    </div>
                </Container>
            </Grid>
        </Grid>
    );
};

export default AddEntry;