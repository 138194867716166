import React, { useEffect, useState } from 'react';
import "./Home.css";
import Navbar from '../components/Navbar';
import DataTable from '../components/Datatable';
import axios from "axios";
import Header from '../components/Header/Header.js';

const Home = () => {
    const [cars, setCars] = useState([]);
    const REACT_APP_BASE_API_URL = process.env.REACT_APP_BASE_API_URL
    console.log(REACT_APP_BASE_API_URL)
    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const response = await axios.get(`${REACT_APP_BASE_API_URL}/view/cars`);
            setCars(response.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleFileUpload = () => {
        // Trigger re-render by fetching data again
        fetchData();
    };

    return (
        <div className='Home'>
            <Header />
            <Navbar onFileUpload={handleFileUpload} />
            <DataTable hideMundyName={false} data={cars} />
        </div>
    );
}

export default Home;
